import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBanner } from '@a1-ict/fsbo/bannerSlice';
import { selectBanner } from '@a1-ict/fsbo/bannerSlice/selectors';

import Layout from '../components/layouts/Layout';
import Carousel from '../components/Carousel';
import SearchForm from '../components/SearchForm';
import SilverProperties from '../components/Properties/SilverProperties';
import GoldenProperties from '../components/Properties/GoldenProperties';
import Investors from '../components/Investors';
import SEO from '../components/Seo';
import SmartBanner from 'react-smartbanner';
import 'react-smartbanner/dist/main.css';
import { countBanner } from '@a1-ict/fsbo/bannerSlice/requests';
import BuildingProperties from '../components/Properties/BuildingProperties';
import { selectIsLoggedIn, selectIsRefreshingToken, selectUserData } from '@a1-ict/core/authSlice';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';

import { fetchCarousel } from '@a1-ict/fsbo/generalSlice';
import { selectCarousel } from '@a1-ict/fsbo/generalSlice/selectors';

const IndexPage = () => {
  const urlPath = useLocation();
  const dispatch = useDispatch();
  const banner = useSelector(selectBanner);
  const carousel = useSelector(selectCarousel);

  const isRefreshingToken = useSelector(selectIsRefreshingToken);
  const isLoggedIn = useSelector(selectIsLoggedIn);

  useEffect(() => {
    if (!isRefreshingToken && urlPath?.state?.onRefreshTokenRedirect && isLoggedIn)
      navigate(`${urlPath?.state?.onRefreshTokenRedirect}`);
  }, [isRefreshingToken, isLoggedIn]);

  useEffect(() => {
    dispatch(fetchBanner());
    dispatch(fetchCarousel());
  }, []);

  const handleBannerClick = (id) => {
    dispatch(countBanner({ id: id }));
  };

  return (
    <>
      <SmartBanner title="Имот" className="header" daysHidden={1} />
      <Layout>
        <SEO />
        <header className="header">
          {carousel.length > 0 && <Carousel items={carousel}></Carousel>}

          <SearchForm></SearchForm>
        </header>
        <main className="container-fluid">
          <Container fluid="xl">
            <section className="latest_properties_section">
              <GoldenProperties />
            </section>
            <section>
              <SilverProperties />
            </section>
          </Container>
          <section className="popular_areas_section px-0 mb-3 py-5">
            <Container fluid="xl">
              <Row className="latest_properties_title flex-column align-items-center">
                <h2>Нови сгради и проекти</h2>
              </Row>
              <section>
                <BuildingProperties />
              </section>
            </Container>
          </section>

          <Container fluid="xl">
            <Row className=" flex-column align-items-center">
              <h2>Нашите партньори</h2>
            </Row>
            <Row className="flex-column align-items-center">
              <Investors />
            </Row>
          </Container>
          {banner && banner.image && (
            <section className="banner_section px-0 mb-5 py-3">
              <Row className="my-2">
                <Col xs={12}>
                  <a href={banner.url} target="_blank" className="footer__banner mx-auto">
                    <button
                      style={{ border: 'none', outline: 'none', padding: 0 }}
                      onClick={() => handleBannerClick(banner.id)}
                    >
                      <img
                        className="footer__banner_img"
                        src={`${process.env.GATSBY_BACKEND}${banner.image.url}`}
                        alt="banner"
                      />
                    </button>
                  </a>
                </Col>
              </Row>
            </section>
          )}
        </main>
      </Layout>
    </>
  );
};

export default IndexPage;
