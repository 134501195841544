import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';

import SilverCard from './SilverCard';
import { useDispatch, useSelector } from 'react-redux';

import { fetchSilver } from '@a1-ict/fsbo/propertySlice';
import { selectSilver } from '@a1-ict/fsbo/propertySlice/selectors';

import { useLocation } from '@a1-ict/gatsby/utils/locationContext';
import { useBreakpoint } from '@a1-ict/gatsby/utils/breakpoint';
import useDeepEffect from '@a1-ict/core/utils/useDeepEffect';

const SilverProperties = () => {
  const itemsCount = 8;
  const [silverPropertiesCount, setSilverPropertiesCount] = useState(itemsCount);
  const silverProperties = useSelector(selectSilver);
  const currentLocation = useLocation();
  // const breakpoints = useBreakpoint();
  const dispatch = useDispatch();

  useDeepEffect(() => {
    // if (currentLocation) {
    //   if (currentLocation.lat && currentLocation.lng) {
    //     queryParams = {
    //       CentreLatitude: currentLocation.lat,
    //       CentreLongitude: currentLocation.lng,
    //       Radius: 15,
    //     };
    //   }
    //   dispatch(fetchSilver({ dispatch, settings, queryParams }));
    // }
    dispatch(fetchSilver({}));
  }, [currentLocation]);

  return (
    <>
      {silverProperties && silverProperties.length > 0 && (
        <>
          <Row>
            {silverProperties.slice(0, silverPropertiesCount).map((pro) => {
              return <SilverCard key={pro.id} property={pro} />;
            })}
          </Row>
          <Row>
            {silverProperties && silverProperties.length > silverPropertiesCount && (
              <Col sm={12} className="pb-4 d-flex flex-row justify-content-center ">
                <button
                  className={` btn animated `}
                  onClick={() => {
                    setSilverPropertiesCount(() => {
                      silverPropertiesCount + itemsCount;
                    });
                  }}
                >
                  <span>Покажи още</span>
                </button>
              </Col>
            )}
          </Row>
        </>
      )}
    </>
  );
};

export default SilverProperties;
