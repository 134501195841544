import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLogos } from '@a1-ict/fsbo/bannerSlice';
import { selectLogos } from '@a1-ict/fsbo/bannerSlice/selectors';
import { settings } from '../settings';
import { countLogo } from '@a1-ict/fsbo/bannerSlice/requests';

const Investors = () => {
  const dispatch = useDispatch();
  const logos = useSelector(selectLogos);

  useEffect(() => {
    dispatch(fetchLogos({ dispatch, settings, count: 5 }));
  }, []);

  const countLogos = (id) => {
    dispatch(countLogo({ dispatch, settings, id: id }));
  };

  return (
    <div className="investors_section">
      {logos &&
        logos.map((logo) => {
          return (
            <a
              key={logo.id}
              href={logo.url}
              target="_blank"
              className="m-4"
              onClick={() => countLogos(logo.id)}
            >
              <div className="investors_section_item">
                <img src={`${settings.api_url}${logo.image.url}`} alt={logo.investor_name} />
              </div>
            </a>
          );
        })}
    </div>
  );
};

export default Investors;
