import React from 'react';

const Grid = ({ arrayList, variant = 'default', renderFunction }) => {
  const rowCreator = (items, extraClass = '') => {
    return (
      <div key={items[0].id} className={`grid__row ${extraClass}`}>
        {items.map((item) => {
          return renderFunction(item);
        })}
      </div>
    );
  };

  const gridBuilder = (arrlist) => {
    let num = arrlist.length;
    let result = [];

    if (num === 1) {
      result.push(rowCreator(arrlist, 'col-one'));
      return result;
    }

    if (num === 4) {
      result.push(rowCreator(arrlist.slice(-4), 'col-four'));
      return result;
    }

    switch (num % 3) {
      case 2:
        result.push(rowCreator(arrlist.slice(0, 2), 'col-two'));
        if (num === 2) {
          break;
        }
        for (let i = 2; i < num; i += 3) {
          result.push(rowCreator(arrlist.slice(i, i + 3)));
        }
        break;
      case 1:
        for (let i = 0; i < num - 4; i += 3) {
          result.push(rowCreator(arrlist.slice(i, i + 3)));
        }
        result.push(rowCreator(arrlist.slice(-4), 'col-four'));
        break;
      default:
        for (let i = 0; i < num; i += 3) {
          result.push(rowCreator(arrlist.slice(i, i + 3), variant === 'equal' ? 'col-three' : ''));
        }
    }
    return result;
  };

  return <div className="grid px-2">{arrayList && gridBuilder(arrayList)}</div>;
};

export default Grid;
