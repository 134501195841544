import useDeepEffect from '@a1-ict/core/utils/useDeepEffect';
import React from 'react';
import useFilters from './Filters/useFilters';
import SearchFormLocation from '../components/Filters/Fields/SearchFormLocation';

const SearchForm = () => {
  const filters = useFilters();

  useDeepEffect(() => {
    filters.setSearchParams({});
  }, []);

  const handleSearch = () => {
    filters.search({ url: '/buy-view' });
  };

  return (
    <div className="search_form search_form_wrapper">
      <div className="search_form_container">
        <div className="search_form_input">
          <SearchFormLocation
            size="sm"
            onAfterChange={handleSearch}
            noOptionsMessageProp="Моля въведете град или квартал."
            placeholder="Търсете по град и квартал или по местоположение"
          />
        </div>
        {/* <button className="search_form_button btn animated" onClick={handleSearch}>
          <span>ТЪРСИ</span>
        </button> */}
      </div>
    </div>
  );
};

export default SearchForm;
