import React from 'react';
import { Col, Card, CardBody, CardTitle } from 'reactstrap';
import { FaCamera } from '@react-icons/all-files/fa/FaCamera';
import { FaVideo } from '@react-icons/all-files/fa/FaVideo';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { BiMap } from '@react-icons/all-files/bi/BiMap';
import silverBadge from '../../images/silver.png';
import NoImage from '../../images/No-Image-Icon.png';
import Icon from '../Common/Icons';
import FavoriteButton from '../Common/Buttons/FavoriteButton';
import { getConstructionTypeIcon, getFeatureIcon, mainFeatures } from './helpers';

function SilverCard({ property }) {
  const gatsbyImg =
    property.gatsbyImages &&
    property.gatsbyImages[0] &&
    property.gatsbyImages[0].localFile.childImageSharp.gatsbyImageData;

  const {
    is_building,
    bedrooms,
    bathrooms,
    size,
    yard_size,
    construction_type,
    building_floors,
    floor,
  } = property;

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  return (
    <Col sm={6} md={4} xl={3} className=" m-md-0 px-md-3  py-xs-4 px-sm-3 px-xs-5">
      <Card className={`property_card d-flex flex-column h-100`}>
        <div className="property_card_header">
          <Link to={`/property/${property.id}`} className="links">
            <div className="property_card_header_img_container_silver m-2 overflow-hidden">
              {gatsbyImg ? (
                <GatsbyImage
                  image={gatsbyImg}
                  alt="Alt"
                  imgClassName="property_card_image"
                  style={{ height: '100%' }}
                />
              ) : (
                <img
                  src={
                    property.images && property.images[0]
                      ? `${process.env.GATSBY_BACKEND}${property.images[0].url}`
                      : NoImage
                  }
                  alt="Alt"
                  className="property_card_header_img_container property_card_image"
                ></img>
              )}
            </div>
          </Link>
          {/* {property.featured && (
            <div className="property_card_tag">Featured</div>
          )} */}
          <div className="property_card_badge">
            <img alt="badge" src={silverBadge} width="60px" height="70px"></img>
          </div>
        </div>
        <div className="d-flex flex-column">
          <CardBody className="px-3 py-2 py-md-1 ">
            <div className="d-flex justify-content-between align-items-start mb-1">
              <div className="property_card__media d-flex align-items-center ">
                <div className="property_card_icon mr-1">
                  <FaCamera></FaCamera>
                </div>{' '}
                <span className="mr-2">{property.images && property.images.length}</span>
                {property.video && (
                  <>
                    <div className="property_card_icon mr-1">
                      <FaVideo fontSize="18"></FaVideo>
                    </div>{' '}
                    <span>1</span>{' '}
                  </>
                )}
              </div>

              <div className="d-flex flex-column align-items-end">
                {property.price && (
                  <div className="property_card_price mb-1">
                    <span className="property_card_price_label">
                      {numberWithCommas(property.price)}
                    </span>
                    <span> EUR</span>
                  </div>
                )}
                {property.square_price && (
                  <div className="property_card__avg_price">
                    {property.square_price?.toFixed(2)} EUR/кв.м.
                  </div>
                )}
              </div>
            </div>
            <CardTitle tag="h6" className="property_card_title_container mb-1 font-weight-normal">
              <Link
                to={`/property/${property.id}`}
                className="property_card_title  links"
                state={{ resetFilter: true }}
              >
                {property.title}
              </Link>
              <FavoriteButton propertyId={property.id} isFavorite={property.is_favorite} />
            </CardTitle>
            <div className="property_card_address mt-0 mb-1 d-flex align-items-center">
              {(property.street || property.neighborhood || property.city) && (
                <div className="property_card_icon">
                  <BiMap></BiMap>
                </div>
              )}
              {property.street && <span className="property_card_street">{property.street},</span>}
              {property.neighborhood && (
                <span className="property_card_area ml-1">
                  <Link
                    to={`/buy-view?NeighborhoodId=${property.neighborhood_id}`}
                    className="links"
                    state={{ resetFilter: true }}
                  >
                    {property.neighborhood},
                  </Link>
                </span>
              )}
              <span className="property_card_city ml-1">
                <Link
                  to={`/buy-view?CityId=${property.city_id}`}
                  className="links"
                  state={{ resetFilter: true }}
                >
                  {property.city}
                </Link>
              </span>
            </div>
            <div className="property_card__silver_description ">{property.description}</div>
            <div className="property_card_details d-flex flex-wrap mt-2">
              {floor && (
                <span className=" mr-2 text-nowrap mb-1">
                  <span className="pr-1">
                    <Icon icon="stairs" size="sm" />
                  </span>
                  {floor} от {building_floors}
                </span>
              )}
              {((is_building && building_floors) || (building_floors && !floor)) && (
                <span className=" mr-2 text-nowrap mb-1">
                  <span className="pr-1">
                    <Icon icon="stairs" size="sm" />
                  </span>
                  Етажи: {building_floors}
                </span>
              )}
              {property.rooms && (
                <span className=" mr-2 text-nowrap mb-1">
                  <span className="pr-1">
                    <Icon icon="rooms" size="sm" />
                  </span>
                  {property.rooms}
                </span>
              )}
              {property.bathrooms && (
                <span className=" mr-2 text-nowrap mb-1">
                  <span className="pr-1">
                    <Icon icon="bathrooms" size="sm" />
                  </span>
                  {property.bathrooms}
                </span>
              )}
              <span className="mr-2 text-nowrap mb-1">
                <span className="pr-1">
                  <Icon icon="size" size="sm" />
                </span>
                <span className="mr-1">{property.size} кв.м.</span>
              </span>
              <span className="mr-2 text-nowrap mb-1">
                <span className="pr-1">
                  <Icon icon={getConstructionTypeIcon(property.construction_type)} size="sm" />
                </span>
                <span className="mr-1">{property.construction_type}</span>
              </span>
              {property.features
                .filter((feature) => mainFeatures.includes(feature.name))
                .map((feature) => (
                  <span key={feature} className="mr-2 text-nowrap mb-1">
                    <span className="pr-1">
                      <Icon icon={getFeatureIcon(feature.text)} size="sm" />
                    </span>
                    <span className="mr-1">{feature.text}</span>
                  </span>
                ))}
            </div>
          </CardBody>
        </div>
      </Card>
    </Col>
  );
}

export default SilverCard;
