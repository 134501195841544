import React from 'react';
import { Link } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from '@a1-ict/gatsby/utils/locationContext';
import useDeepEffect from '@a1-ict/core/utils/useDeepEffect';
import { fetchBuildings } from '@a1-ict/fsbo/propertySlice';
import { selectBuildings } from '@a1-ict/fsbo/propertySlice/selectors';
import Grid from '../Grid';
import { settings } from '../../settings';
import { thousandsSeparator } from './helpers';

const { api_url } = settings;

const jsxToRender = (item) => {
  return (
    <Link
      to={`/property/${item.id}`}
      key={item.id}
      style={{
        backgroundImage: `
    url("${api_url}${item?.images?.length > 0 && item.images[0].url}")`,
      }}
      className="popular_area"
    >
      <div className="popular_area_overlay"></div>

      <div className="popular_area_content p-2 d-flex flex-column">
        <h5 className="pb-0 mb-1">{item.title}</h5>
        <span className=" ">
          Цена{' '}
          {item.is_price_negotiated
            ? 'по договаряне'
            : `на кв.м. ${thousandsSeparator(Math.round(item.square_price))} EUR`}
        </span>
        <span className=" ">
          {' '}
          {item.neighborhood} {item.neighborhood && ', '} {item.city}
        </span>
      </div>
    </Link>
  );
};

const BuildingProperties = () => {
  const buildings = useSelector(selectBuildings);
  const currentLocation = useLocation();
  //   const breakpoints = useBreakpoint();
  const dispatch = useDispatch();

  useDeepEffect(() => {
    let queryParams = {};

    if (currentLocation) {
      if (currentLocation.lat && currentLocation.lng) {
        queryParams = {
          CentreLatitude: currentLocation.lat,
          CentreLongitude: currentLocation.lng,
          Radius: 15,
        };
      }
      dispatch(fetchBuildings({ queryParams }));
    }
  }, [currentLocation]);

  return <Grid arrayList={buildings} renderFunction={jsxToRender}></Grid>;
};

export default BuildingProperties;
