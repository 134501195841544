import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useBreakpoint } from '@a1-ict/gatsby/utils/breakpoint';

const indicatorStyles = {
  background: 'rgba(0,0,0,0)',
  overflow: 'hidden',
  borderRadius: '40%',
  width: '16px',
  height: '16px',
  boxShadow: 'inset 0 0 0 2px #ffffff',
  margin: '5px',
  border: 'none',
};

const CarouselSlider = ({ items }) => {
  const breakpoints = useBreakpoint();

  return (
    <div className="header_carousel">
      <Carousel
        autoPlay={true}
        axis={'vertical'}
        infiniteLoop={true}
        dynamicHeight={true}
        centerMode={true}
        animationHandler="fade"
        transitionTime={800}
        interval={3000}
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          if (isSelected) {
            return (
              <li style={{ listStyle: 'none' }}>
                <button
                  style={{ ...indicatorStyles, background: '#fff' }}
                  aria-label={`Selected: ${label} ${index + 1}`}
                ></button>
              </li>
            );
          }
          return (
            <li style={{ listStyle: 'none' }}>
              <button
                style={indicatorStyles}
                onClick={onClickHandler}
                className="header_carousel_bullet"
                value={index}
                key={index}
                aria-label={`${label} ${index + 1}`}
              ></button>
            </li>
          );
        }}
      >
        {items.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                height: '450px',
                padding: `0 ${breakpoints.md ? 3 : 10}rem `,
              }}
            >
              <h1 className="header_carousel_item_header">{item.title}</h1>
              <p className="header_carousel_item_text">{item.description}</p>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default CarouselSlider;
